import React, { createContext, useContext } from "react";

import axios from "axios";

import { AuthContext } from "components/AuthContext";

const HttpClientContext = createContext();

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.hellosanctum.com/api/v1/"
    : "http://localhost:8000/api/v1/";

const httpClient = axios.create({
  baseURL,
  paramsSerializer: {
    indexes: null,
  },
});

const HttpClientProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const get = async ({ url, params }) => {
    let headers = {};
    if (user) {
      headers = {
        Authorization: `Token ${user?.token}`,
      };
    }

    try {
      const response = await httpClient.get(url, {
        headers,
        params,
      });
      if (response.status >= 400) {
        handleFailedRequest(response);
      }
      return response;
    } catch (err) {
      handleFailedRequest(err);
    }
  };

  const post = async ({ url, payload }) => {
    let headers = {};
    if (user) {
      headers = {
        Authorization: `Token ${user?.token}`,
      };
    }

    try {
      const response = await httpClient.post(url, payload, { headers });
      if (response.status >= 400) {
        handleFailedRequest(response);
      }
      return response;
    } catch (err) {
      handleFailedRequest(err);
    }
  };

  const handleFailedRequest = ({ response }) => {
    let msg = "";
    if (response.status >= 500) {
      msg =
        "Oops! We're having trouble with that request. Our engineers have been alerted. We'll get a fix in place as soon as possible.";
    } else if (response.status >= 400) {
      msg = response.message || response.data?.message;
    }
    let error = new Error(msg);
    error.code = response.status;
    throw error;
  };

  return <HttpClientContext.Provider value={{ get, post }}>{children}</HttpClientContext.Provider>;
};

export { HttpClientContext, HttpClientProvider, httpClient };
