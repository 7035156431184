import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { HttpClientContext } from "components/HttpClient";
import { doubleSpace, ErrorMessage, Paragraph } from "components/sharedComponents";
import styled from "styled-components";

import { Shift, StyledColumn } from "./Shift";

export const ShiftList = () => {
  const { get } = useContext(HttpClientContext);
  const [error, setError] = useState("");
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    const today = new Date();
    const startDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

    const getShifts = async () => {
      try {
        const res = await get({
          url: "shifts/status/",
          params: {
            start_date: startDate,
          },
        });
        setShifts(res.data?.shifts || []);
      } catch (err) {
        setError(err.message);
      }
    };

    getShifts();
  }, [get]);

  return (
    <StyledShiftList>
      <Container fluid>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Row className="header">
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Status</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Caregiver</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Patient</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Shift Date</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Shift Time</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Booking Confirmed</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Early Reminder</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Final Reminder</Paragraph>
            </StyledColumn>
          </Col>
        </Row>
        {shifts && shifts.map((item, i) => <Shift key={i} setError={setError} {...item} />)}
      </Container>
    </StyledShiftList>
  );
};

const StyledShiftList = styled.section`
  padding: ${doubleSpace} 0;
  .header {
    p {
      font-weight: 600;
    }
  }
`;
