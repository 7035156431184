import React from "react";

import { formatDateTime } from "utils/dateTime";

import { Paragraph, tripleSpace } from "components/sharedComponents";
import styled from "styled-components";

export const Greeting = ({ confirmation }) => {
  const { shift } = confirmation;
  const { recipient } = shift;
  const { month, day, hours, minutes, period } = formatDateTime(shift.start_date);

  return (
    <StyledGreeting>
      <Paragraph>
        Hi {confirmation.provider.first_name}! You have a shift coming up on {month} {day} at{" "}
        {hours}:{minutes} {period} with {recipient.first_name} {recipient.last_name}. Please confirm
        you can make it!
      </Paragraph>
    </StyledGreeting>
  );
};

const StyledGreeting = styled.div`
  margin-bottom: ${tripleSpace};
  p {
    font-weight: 500;
    line-height: 32px;
  }
`;
