import React from "react";

import { formatDateTime } from "utils/dateTime";

import { SectionSubheader, Subheader, tripleSpace } from "components/sharedComponents";
import styled from "styled-components";

export const ShiftTitle = ({ shift }) => {
  const { recipient } = shift;
  const { month, day } = formatDateTime(shift.start_date);

  return (
    <StyledShiftTitle>
      <Subheader>
        {month} {day}
      </Subheader>
      <SectionSubheader>
        {recipient.first_name} {recipient.last_name}
      </SectionSubheader>
    </StyledShiftTitle>
  );
};

const StyledShiftTitle = styled.div`
  margin-bottom: ${tripleSpace};
`;
